import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/download-icon.svg'


const _hoisted_1 = { class: "game-card card-box" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "downloads-counter" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $props.icon,
      alt: $props.header,
      class: "game-icon"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", null, _toDisplayString($props.header), 1),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Download",
        width: "17",
        height: "17"
      }, null, -1)),
      _createTextVNode(" " + _toDisplayString($props.downloads), 1)
    ]),
    _createElementVNode("a", {
      class: "btn app-store",
      href: $props.appStoreLink
    }, "App Store", 8, _hoisted_4),
    _createElementVNode("a", {
      class: "btn google-play",
      href: $props.googlePlayLink
    }, "Google Play", 8, _hoisted_5)
  ]))
}