<template>
    <LayoutHeader/>
    <main class="main-content">
        <h1 class="main-content__heading">Terms of Service</h1>
        <section class="main-content__text-block text-block">
            <p class="text-block__text">
                The following Terms of Service, together with the
                <router-link class="text-block__inline-link" to="/privacy-policy">Privacy Policy</router-link>
                and all other policies referenced
                herein or other documents to which they refer to constitute the binding legal agreement between
                Brightika, Inc. (collectively, “we,” the “Brightika” or “Contractor”) and the customers (the "Customer",
                “you” or the “User”) defined in the insertion order (“the Insertion Order”). By signing an Insertion
                Order or using the Services, the Customer acknowledges and agrees that the Customer is subject to these
                Terms and that the Customer accepts these Terms of Use. Unless designated as replacing a specific
                outstanding Insertion Order, a new Insertion Order will be considered to be in addition to then
                outstanding Insertion Order.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Brightika may change these Terms from time to time, at its sole discretion and without any notice.
                Brightika will notify regarding substantial changes of these Terms on its homepage of the Brightika
                website. These substantial changes will take effect seven (7) days after such notice was first provided
                on any of the above-mentioned methods. Otherwise, all other changes to these Terms are effective as of
                the stated “Last Revised” date and your continued usage of the Product after the Last Revised date will
                constitute acceptance of, and agreement to be bound by, those changes.
            </p>
            <p class="text-block__text text-block__text--double-margin mb-40">
                Brightika provides the services using its proprietary software (“<b>Software</b>”), and may include
                other
                services provided by Brightika, as defined in the relevant Insertion Order (collectively, the
                “<b>Services</b>”).
            </p>
            <ol class="text-block__list">
                <li class="text-block__itemsym_head">
                    <h3>Usage of the Services</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You may access the Software and Services (collectively with any applicable
                                documentation, the “<b>Product</b>”). If you are downloading, accessing or using the
                                Product on
                                behalf of an entity, you represent and warrant that you are authorized to accept these
                                Terms on this entity’s behalf, and that this entity agrees to take responsibility for
                                usage of the Products in compliance with these Terms (“<b>Authorized Users</b>”);
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Some features of our Services may be subject to additional terms and conditions, which
                                you should read before making usage of those features, and they add up hereof;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Upon Brightika’s acceptance of your Insertion Order and for the duration of the term
                                defined in the Insertion Order (the “Term“), you have a non-exclusive, revocable,
                                non-assignable, non-transferable, limited right to use the Software to receive the
                                Services solely for your internal business operations and subject to the terms contained
                                hereunder. You may access the Software and Services (collectively with any applicable
                                documentation, the “Product”) via Authorized Users;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                The Services are designed to help the Customer in creating, managing and optimizing its
                                advertising campaigns but the Customer remains responsible for how the Customer uses the
                                Services and for achieving the intended goals and results of Customer's advertising
                                campaigns. The Services operate between the Customer and third parties’ Products on
                                which the advertising campaigns are being conducted, and it is the responsibility of the
                                Customer to ensure that the output and results of the Services meet the Customer's
                                expectations and requirements;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                The Services may be unavailable from time to time, may be offered on a limited basis, or
                                may vary depending on your region or device. You acknowledge that we make no warranties
                                as to its availability or that the Services will operate continuously or error-free. We
                                strive to keep the Services up and running; however, all online services suffer
                                occasional disruptions and outages. We accept no responsibility for the unavailability
                                of this services, or any interruption or malfunction for any reason whatsoever
                                (including failures of our Internet provider or web hosting service provider, third
                                party intrusions or force majeure). Brightika is not liable for any disruption or loss
                                you may suffer as a result of not being able to access the Services. In the event of an
                                outage, you may not be able to retrieve your User Content.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Fees</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                In consideration for the Services, you shall pay Brightika the amounts set forth on each
                                Insertion Order (“Fees”);
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                The Customer will be responsible for any charges incurred for i) processing wire
                                transfers; ii) bank commissions; or iii) currency exchange or fluctuations;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Payment Terms. Any payment not received by Brightika within such period shall accrue
                                interest at a rate of one and a half percent (1.5%) per month. All Fees are
                                non-cancellable and non-refundable. All Fees are stated and payable in US Dollars, and
                                are exclusive of VAT or other taxes that may apply, which shall be added to Brightika’s
                                invoice(s) at the appropriate rate. You agree to make all payments under each Insertion
                                Order and these Terms without set-off or counterclaim and free and clear of any
                                withholding or deduction (save as required by law) for any present or future taxes,
                                levies, imposts, duties or other charges.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Usage Restrictions</h3>
                    <p class="text-block__text mb-40">
                        <b>You may not, whether by yourself or anyone on your behalf</b>: (i) modify, alter, create
                        derivative
                        works from, reverse engineer, decompile, or disassemble any part of the Product, nor attempt in
                        any other manner to obtain the source code or otherwise reduce to human-perceivable form any
                        part of the Product; (ii) frame, mirror, republish, download, display, transmit, or distribute
                        all or any portion of the Product in any form or media or by any means; (iii) remove any
                        proprietary notices, labels, or marks on or in any part of the Product, including without
                        limitation any trademark or copyright notices; (iv) disclose the results of testing or
                        benchmarking of the Product to any third party, or access or use the Product or any part thereof
                        in order to build or support, and/or assist a third party in building or supporting, products or
                        services which are competitive to Brightika’s; (v) use the Product to provide services to third
                        parties; (vi) disseminate, distribute, disclose, or copy any printed documentation which
                        accompanies the Product; (vii) sublicense, rent, or lease any portion of the Product; (viii)
                        host, outsource, display, or commercially exploit the Product, or use the Product as part of a
                        facility management, timesharing, service provider, or service bureau arrangement; (ix) use the
                        Product in any manner not expressly authorized by these Terms; (x) attempt to obtain, or assist
                        third parties in obtaining, access to the Product, the Services and/or documentation; (xi) take
                        any actions which (i) would disable the Product or impair in any way its operation based on the
                        elapsing of a period of time, the exceeding of an authorized number of copies, or the
                        advancement to a particular date or other numeral (referred to as “time bombs”, “time locks”, or
                        “drop dead” devices); or (ii) would prevent Brightika to access the Product for the purposes of
                        its operations or (xii) infringe or violate any of the Terms.
                    </p>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Intellectual Property</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You retain all ownership and intellectual property rights in and to your Customer Data
                                and all data generated by you during your usage of the Services, you hereby grant
                                Brightika the right to use such data (i) in order to provide the Services to you and
                                (ii) in aggregate anonymized form for the Brightika’s internal business purposes. You
                                acknowledge and agree that Brightika and/or its licensors own all intellectual property
                                rights in the Product. Except as expressly stated herein, these Terms do not grant you
                                or Authorized Users any rights to, or in, patents, copyrights, database rights, trade
                                secrets, trade names, trade-marks (whether registered or unregistered), or any other
                                rights or licenses in respect of the Product or other intellectual property of
                                Brightika. The rights provided under these Terms are granted to you alone, and shall not
                                be considered granted to any subsidiary or holding comply of yours, unless expressly
                                authorized in writing by Brightika;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                In the event you elect to receive any creative (including without limitation, playable
                                ads) from Brightika, as between the Parties, you shall own such creative, but excluding
                                the portion thereof which constitutes Brightika IP (as defined herein). The Brightika
                                IP shall mean Brightika’s technology, techniques, methods, systems, know-how, technical
                                information, confidential information and intellectual property rights, that existed
                                before the commencement of the Services or that were developed independently of the
                                Services provided to you under an Insertion Order;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                In the event that you provide to Brightika any suggestions, comments and feedback
                                regarding the Product, you hereby grant Brightika and its licensors a perpetual,
                                irrevocable, worldwide, royalty-free, fully paid-up license grant to freely use, have
                                used, sell, modify, reproduce, transmit, license, sublicense (through multiple tiers of
                                sublicensees), distribute (through multiple tiers of distributors), and otherwise
                                commercialize such feedback in connection with the Product or related technologies.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Your Obligations</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You represent that during the Term, you shall: (i) be and remain exclusively responsible
                                for all ad content, data and information provided to Brightika for the purpose of
                                performing the Services, and that all such content, data and information has been and
                                shall be legally obtained, does not and will not infringe any third party rights, and is
                                not defamatory, obscene, abusive, invasive of privacy or illegal, (ii) allow Brightika,
                                its agents, subcontractors, and third-party service providers, to access, use, transfer,
                                display, reproduce, and modify your ad content, data and information for the sole
                                purpose of providing the services, (iii) hold and any necessary rights to allow the use
                                of your ad content, data and information as contemplated hereunder, (iv) comply with all
                                applicable laws and regulations with respect to your activities under these Terms, (v)
                                carry out all other responsibilities set out in these Terms in a timely and efficient
                                manner, (vi) obtain and shall maintain all necessary licenses, consents, and permissions
                                necessary for Brightika, its contractors and agents to perform their obligations under
                                these Terms, (vii) ensure that your network and systems comply with the relevant
                                specifications provided by Brightika from time to time, (viii) be solely responsible for
                                procuring and maintaining your network connections telecommunications links from your
                                systems to Brightika’s data centers, and all problems, conditions, delays, delivery
                                failures and all other loss or damage arising from or relating to your network
                                connections or telecommunications links or caused by the internet, (ix) solely
                                responsible and you agree to regularly track, review and verify that the Product is duly
                                performing in accordance with your requirements, specifications, expectations and goals.
                                Without derogating from any of the foregoing or from the provisions of Sections 10 and
                                11 below, you acknowledge and agree that in the event that the Product does not perform
                                as required, specified, expected and/or desirable by you, your only recourse is to
                                terminate the Terms in accordance with these Terms and Brightika shall not be liable to
                                you or to any third party with respect thereto. You further acknowledge, represent and
                                agree that your continued use of the Product following any such non-performance, will
                                constitute your acceptance of, and agreement to continue to experience, such Product
                                performance;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You hereby represent and warrants that any content, information and data (including
                                Customer Data) provided to Brightika during the course of your engagement with Brightika
                                shall not include Personal Data of any third party. You and not Brightika shall be
                                responsible for any information provided, made available to Brightika, beyond the scope
                                permitted hereunder. For the purposes of these Terms, “Personal Data” shall mean all
                                information and data of any kind collected, received, held, accessed, maintained,
                                stored, processed, controlled or used, that under applicable law is considered to be
                                personal data or individually-identifiable information from or about an individual,
                                including, but not limited to, an individual’s: (a) personally identifiable information
                                (e.g., name, address, telephone number, email address, financial account number,
                                government-issued identifier, and any other data used or intended to be used to
                                identify, contact or precisely locate a person), (b) Internet Protocol address or other
                                online or persistent identifier, (c) ”Personal Data” as defined by the GDPR and
                                applicable data protection or privacy laws and regulations to which you or Brightika is
                                subject;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You shall own all intellectual property rights, as may exist, related to the Customer
                                App, as well as you confirm the absence of legal obstacles to the execution of the IO.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Third Party Providers</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You acknowledge that the Product will enable or assist you to access the website content
                                of, correspond with, manage your advertising, and purchase services from third parties
                                via third-party websites, and you do so solely at its own risk. Brightika makes no
                                representation or commitment and shall have no liability or obligation whatsoever in
                                relation to the content or use of, or correspondence with, any such third-party website,
                                or any transactions completed, and any contract entered into by you with any such third
                                party. Any contract entered into and any transaction completed via any third-party
                                website is between you and the relevant third party, and not Brightika. Brightika
                                recommends that you refer to the third party’s website terms and conditions and privacy
                                policy prior to using the relevant third-party;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                In your use of the Product and/or Services, you warrant that you will at all times
                                comply with the applicable policies of third party Product provider (as applicable);
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You will use all reasonable endeavors to prevent any unauthorized access to, or use of,
                                the Product and, in the event of any such unauthorized access or use, promptly notify
                                Brightika.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Indemnification</h3>
                    <p class="text-block__text mb-40">
                        Your Indemnification. You shall indemnify, defend, and hold Brightika (including its officers,
                        employees, agents and affiliates) harmless from and against any claims, damages, losses, and
                        liabilities incurred or arising from your failure to comply with, breach or alleged breach of
                        the terms and conditions hereof and/or included by references herein.
                    </p>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Confidentiality</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                By virtue of the Terms or provision of the Product, the parties may have access to
                                information that is confidential to one another (“Confidential Information”). We each
                                agree to disclose only information that is required for the performance of obligations
                                under the Terms. Confidential information shall include without limitation, the terms
                                and pricing under the Terms, the documentation accompanying the Product, information
                                relating to the technology and code contained in the Product, your Customer Data;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                A party’s Confidential Information shall not include information that: (a) is or becomes
                                a part of the public domain through no act or omission of the other party; (b) was in
                                the other party’s lawful possession prior to the disclosure and had not been obtained by
                                the other party either directly or indirectly from the disclosing party; (c) is lawfully
                                disclosed to the other party by a third party without restriction on the disclosure; or
                                (d) is independently developed by the other party without use of or reference to the
                                Confidential Information;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                We each agree to hold each other’s Confidential Information in confidence for a period
                                of ten years from the date of disclosure. Also, we each agree to disclose Confidential
                                Information only to those employees or agents who are required to protect it against
                                unauthorized disclosure in a manner no less protective than under the agreement.
                                Brightika will protect the confidentiality of your data in accordance with the
                                <router-link class="text-block__inline-link" to="/privacy-policy">Privacy Policy
                                </router-link>
                                . Nothing shall prevent either party from disclosing the terms or pricing under
                                the Terms in any legal proceeding arising from or in connection with the Terms or from
                                disclosing the Confidential Information to a governmental entity as required by the
                                effective legislation.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Disclaimer and Warranties</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Brightika disclaims any and all responsibility or liability for the accuracy, content,
                                completeness, legality, reliability, or operability or availability of information or
                                material displayed in search results or posted via the Software by Brightika or third
                                parties. Brightika disclaims any responsibility for the deletion, failure to store,
                                non-transfer, or untimely transfer of any information or material relating to the
                                Service. Brightika disclaims any responsibility for any harm resulting from downloading
                                or accessing any information or material. Under no circumstances shall Brightika be
                                liable to you or any User on account of that the User's usage or misuse of or reliance
                                on the Software or other Services;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                THE SERVICES, AND ALL MATERIALS, INFORMATION, USER CONTENT, PRODUCTS AND SERVICES
                                INCLUDED IN THE SOFTWARE OR ANY SERVICES ARE PROVIDED "AS IS," WITH NO WARRANTIES
                                WHATSOEVER, EITHER EXPRESS OR IMPLIED. BRIGHTIKA EXPRESSLY DISCLAIMS TO THE FULLEST
                                EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING,
                                WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                                AND NON- INFRINGEMENT OF PROPRIETARY RIGHTS. BRIGHTIKA DISCLAIMS ANY WARRANTIES
                                REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF BRIGHTIKA SERVICES
                                AND USE OF THE SOFTWARE. BRIGHTIKA DISCLAIMS, ANY WARRANTIES FOR ANY INFORMATION OR
                                ADVICE OBTAINED THROUGH THE SOFTWARE OR ANY SERVICES. BRIGHTIKA DISCLAIMS ANY WARRANTIES
                                FOR SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED VIA THE SOFTWARE OR RECEIVED
                                THROUGH ANY LINKS PROVIDED BY BRIGHTIKA.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Limitation of liability</h3>
                    <p class="text-block__text mb-40">
                        Under no circumstances will either party be liable for indirect, special, incidental or
                        consequential damages, including, without limitation, lost profit or loss resulting from
                        business interruption or loss of data, even if a party has been advised of the
                        possibility or likelihood of such damages. neither party will be liable to the other
                        party for any delay, loss or damage attributable to any service, product or action of
                        any person other than its own and its employees. Except for gross negligence and willful
                        misconduct, in no event will Brightika’s aggregate liability for any and all claims,
                        losses or damages arising out of or relating to these Terms or any Services (whether in
                        contract, equity, negligence, tort or otherwise) exceed the aggregate fees paid by you
                        to Brightika under these terms during the TWELVE (12) month period immediately preceding
                        the date upon which the applicable cause of action arises. NO ACTION, REGARDLESS OF
                        FORM, ARISING OUT OF OR RELATING TO THESE TERMS MAY BE BROUGHT BY YOU MORE THAN TWELVE
                        (12) MONTHS AFTER THE TERMINATION OR EXPIRATION OF THE RELEVANT INSERTION ORDER.
                    </p>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Termination</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                The Services provided under these Terms shall be provided for the period defined in the
                                Insertion Order unless earlier terminated in accordance with these Terms. All rights and
                                licenses granted to you under these Terms shall immediately terminate upon expiration or
                                termination of the Term, and you shall totally cease usage of the Product, and delete
                                and purge all traces of the Product from your systems and custody. The accrued rights of
                                the parties as at termination, or the continuation after termination of any provision
                                expressly stated to survive or implicitly surviving termination, shall not be affected
                                or prejudiced by the termination or expiration of the Terms;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Termination of Services. Either you or Brightika may terminate the relevant Insertion
                                Order for Services by giving a five (5) days’ written notice at any time for any reason.
                                Brightika may terminate with immediate effect if you breach any condition of these Terms
                                or the applicable Insertion Order under which the breach occurred we reserve the right
                                to refuse to provide, suspend or discontinue the Services in whole or in part, at any
                                time for any reason, including convenience, immediately and without notice in which case
                                you must immediately pay all amounts which have accrued prior to such end, as well as
                                all sums remaining unpaid for the Services.
                            </p>
                        </li>
                    </ol>
                </li>
                <li class="text-block__itemsym_head">
                    <h3>Miscellaneous</h3>
                    <ol class="text-block__list" type="a">
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Miscellaneous. Neither the IO nor the rights, duties or obligations of the Parties may
                                be assigned by either Party without the prior written consent of the other Party, which
                                consent may be withheld in each such Party’s sole and absolute discretion;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Brightika is entitled to disclose information on its engagement with you for advertising
                                and marketing purposes including placement of your logo on Brightika websites. Messages
                                for the mass media, press releases, public and advertising announcements, and other
                                messages relating to the Services under each Insertion Order may be made by the
                                Brightika only with your prior written consent;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                The IO constitutes the entire agreement between the Parties with respect to the subject
                                matter hereof and supersedes and replaces all prior or contemporaneous understandings or
                                agreements, written or oral, regarding such subject matter. Any waiver of any provision
                                of these Terms will be effective only if it is signed by Brightika;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                Governing Law and dispute resolution. The IO provisions shall be governed by and
                                construed and enforced in accordance with the laws of Virginia, USA.
                                Any dispute arising out of or related to the IO, including any question regarding its
                                existence, validity, or termination, shall be exclusively referred to and finally
                                resolved by one arbitrator in American Arbitration Association pursuant to the effective
                                rules of the American Arbitration Association, Commercial Arbitration Rules. The place
                                of the arbitration shall be Washington, District of Columbia, USA. The English language
                                shall be used as the written and spoken language for all matters connected with all
                                references to arbitration. The decision of the arbitrator shall be final and binding on
                                the Parties, save in the event of fraud, manifest mistake or failure by the arbitrator
                                to disclose any conflict of interest.
                                Each Party waives all defenses of lack of personal jurisdiction and forum
                                non-convenience. In the event of any dispute, lawsuit, action, or other proceeding
                                arising out of the subject matter of the IO, the prevailing party will be entitled to
                                recover its attorneys’ fees and costs;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                You and Brightika are independent contractors, and no agency, partnership, joint
                                venture, employee-employer or franchiser-franchisee relationship is intended or created
                                by these Terms;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                User support for the Services may be available at
                                <router-link class="text-block__inline-link" :to="{ path: '/', hash: '#contact' }">
                                    brightika.com/#form
                                </router-link>
                                ;
                            </p>
                        </li>
                        <li class="text-block__itemsym">
                            <p class="text-block__text">
                                We may send you notices and other correspondence to the email address that you specified
                                in the Insertion Order. It is your responsibility to notify us to update your contact
                                details as they change.
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>
            <p class="text-block__text text-block__text--double-margin">
                For information, questions or notification of errors, please contact us at <a
                class="text-block__inline-link" href="mailto:mailto:ua@brightika.com">ua@Brightika.com</a> and we will
                make an effort to reply within a reasonable timeframe.
            </p>
        </section>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    components: {LayoutHeader}
}
</script>

<style lang="scss">
@import "~@/app/styles/text-block.scss";
@import "~@/app/styles/main-content.scss";
</style>
